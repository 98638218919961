import { Link } from "@StarberryUtils"
import * as React from "react"
import { Container } from "react-bootstrap"
import { GetURL } from "../../common/site/functions"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import "./Breadcrumb.scss"
// markup

const Breadcrumb = props => {
  const crtLocation = useLocation()

  const [schemaList, setSchemaList] = React.useState([]);
  let url_1 = ""
  if (props.data.Main_Parent) {
    url_1 = GetURL(props.data.Main_Parent.id)
    if (url_1 == "about-dacha") {
      url_1 = "about-dacha/our-story"
    }
  }
  let url_2 = ""
  if (props.data.Sub_Parent) {
    url_2 = GetURL(props.data.Sub_Parent.id)
  }
  let index = 1
  if (crtLocation?.href?.endsWith("/")) {
    index = 2
  }

  let urlIndex = crtLocation?.href?.split("/")

  let backUrl = urlIndex?.slice(0, urlIndex?.length - index).join("/")

  var elementList = [{ title: "Dacha Real Estate", url: crtLocation?.origin }]

  if (props.data.Main_Parent) {
    elementList.push({
      title: props.data.Main_Parent.Label,
      url: `${crtLocation?.origin}/${url_1}`,
    })
  }
  if (props.data.Sub_Parent) {
    elementList.push({
      title: props.data.Sub_Parent.Label,
      url: `${crtLocation?.origin}/${url_2}`,
    })
  }

  if (props.category && props.url != undefined) {
    elementList.push({
      title: props.category.replace(/-|\s/g, " "),
      //url: `${crtLocation?.origin}/${props.url}`,
      url:`${crtLocation?.origin}/${props.url}${props.url.endsWith('/') ? '' : '/'}`,
    })
  }
  if (props.category && props.url == undefined) {
    elementList.push({
      title: props.category.replace(/-|\s/g, " "),
      url: `${backUrl}`,
    })
  }

  if (props.page && (props.tag === "" || props.tag === undefined)) {
    elementList.push({ title: props.page, url: crtLocation?.href })
  }

  if (props.tag) {
    let element = {
      title: props.name.replace(/\s*-\s*/g, " ").trim(),
      url: crtLocation?.href,
    };
  
    if (props.name === "Properties For sale" || props.name === "Properties For rent") {
      if (crtLocation && crtLocation.href) {
      const segments = crtLocation?.href.split("/").filter(segment => segment.trim() !== "");
      
      if (segments.length >= 3) {
        element.url = segments.slice(0, 5).join("/")+"/";
      }
    }}
      elementList.push(element);
  }
  
  
  

  // var schemaList = [];
  // elementList.forEach((item, i)=>{
  //   schemaList.push({
  //       "@type": "ListItem", 
  //       "position": i+1, 
  //       "name": item?.title,
  //       "item":  item?.url
  //   })
  // })
  React.useEffect(() => {
    const newSchemaList = elementList.map((item, i) => ({
      "@type": "ListItem",
      "position": i + 1,
      "name": item.title,
      "item": item.url
    }));
    setSchemaList(newSchemaList);
  }, [elementList]);

 
  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": ${JSON.stringify(schemaList, null, 2)}
            }`}
        </script>
      </Helmet>
      <section className="breadcrumb-wrap">
        <Container>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb text-xxs">
              <li class="breadcrumb-item one ">
                <Link to="/">Home</Link>
              </li>
              {props.data.Main_Parent && (
                <li class="breadcrumb-item one ">
                  <Link to={`/${url_1}`}>{props.data.Main_Parent.Label}</Link>
                </li>
              )}
              {props.data.Sub_Parent && (
                <li class="breadcrumb-item one ">
                  <Link to={`/${url_2}`}>{props.data.Sub_Parent.Label}</Link>
                </li>
              )}

              {props.category && props.url != undefined ? (
                <li class="breadcrumb-item one">
                  <Link to={`/${props.url}`}>{props.category}</Link>
                </li>
              ) : (
                ""
              )}

              {props.category && props.url == undefined ? (
                <li class="breadcrumb-item one">
                  <Link to="../">{props.category}</Link>
                </li>
              ) : (
                ""
              )}

              {(props.tag === "" || props.tag === undefined) && props.page && (
                <li class="breadcrumb-item active">{props.page}</li>
              )}
              {props.tag === "details" ? (
                <li class="breadcrumb-item active" aria-current="page">
                  {props.name.replace(/-|\s/g, " ")}
                </li>
              ) : (
                ""
              )}
              {props.tag === "detail-page" ? (
                <li class="breadcrumb-item active" aria-current="page">
                  {props.name.replace(/-|\s/g, " ")}
                </li>
              ) : (
                ""
              )}
            </ol>
          </nav>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Breadcrumb
